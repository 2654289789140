.property-detail-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .slideshow-container {
    width: 100%;
    display: flex;
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 20px;
    position: relative;
  }
  
  .slide {
    min-width: 100%;
    transition: transform 0.5s ease;
    display: none;
  }

  .slide.active {
    display: block;
  }

.prev,
.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  outline: none;
  font-size: 20px;
}

.prev {
  left: 0;
}

.next {
  right: 0;
}
  
  .slide img {
    width: 100%;
    border-radius: 10px;
  }
  
  .address-container {
    text-align: center;
    margin-bottom: 20px;
  }

  .address-container,
.fast-facts-container {
  margin-bottom: 20px;
}
  
  .address {
    font-size: 1.5em;
    margin: 0;
    color: #333;
  }
  
  .suggestion {
    font-size: 1em;
    color: #666;
    margin-top: 5px;
  }
  
  .description-container {
    width: 100%;
    text-align: left;
  }
  
  .description {
    font-size: 1em;
    color: #333;
    line-height: 1.5;
  }
  
  .info-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .address-fast-facts-container {
    flex: 1;
  }
  
  .suggestion-title,
  .description-title {
    /* Center-align text */
    text-align: center;
  }
  
  .fast-facts-container {
    margin-top: 20px;
    padding: 20px;
    background-color: #6d77e1;
    border-radius: 8px;
    color: #ffff;
  }
  
  .fast-facts-title {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .fast-facts-list {
    list-style-type: none;
    padding: 0;
  }
  
  .fast-facts-list li {
    margin-bottom: 5px;
  }
  