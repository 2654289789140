/* src/ApartmentFormModal.css */

.Modal {
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

input[type="text"],
select,
input[type="range"] {
  display: block;
  margin: 10px 0;
  padding: 8px;
  width: calc(100% - 16px); /* Adjusted width to accommodate padding */
  border: 1px solid #cad7ed;
  box-sizing: border-box; /* Ensures padding is included in the width */
}

input[type="range"] {
  width: 100%;
}

button {
  padding: 10px 20px;
  margin-top: 20px;
}

label {
  display: block;
  margin-top: 10px;
}

/* Custom styles for react-range */
.range-track {
  height: 6px;
  width: 100%;
  background: lightgrey;
  position: relative;
  border-radius: 3px;
}

.range-thumb {
  height: 20px;
  width: 20px;
  background-color: blue;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.range-highlight {
  position: absolute;
  height: 6px;
  background-color: blue;
  border-radius: 3px;
  top: 0;
}
