.properties-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.empty-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  text-align: center;
  font-size: 1.2em;
  color: #666;
}

.property-header {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.close-button {
  position: absolute;
  top: 50px;
  left: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.close-button:hover {
  color: red;
}

.navigation-buttons {
  margin-top: 20px;
}

.nav-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  font-size: 1em;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.nav-button:hover {
  background-color: #0056b3;
}
