.header-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.menu-item {
  flex: 1;
  display: flex;
  justify-content: center;
}

.header-menu .menu .item > .button {
  width: 100%;
}

.centered-button {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 768px) {
  .centered-button,
  .menu .item > .button {
    display: none;
  }
}
